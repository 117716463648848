import type { CombinedState } from '@reduxjs/toolkit/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { Article } from '~/types/article';
import type { TeaserList } from '~/types/articleList';
import { isHydrateAction } from '~/types/assert';

import { createBaseQuery } from './utils';

export interface TeaserListQueryArgs {
  limit: number;
  offset?: number;
  exclude_slug?: string;
}

type ContentListResponse = Omit<TeaserList, 'offset' | 'limit'>;

function argsToParams(args: Record<string, string | number>) {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(args)) {
    params.append(key, `${value}`);
  }
  return params.toString();
}

function transformListResponse<TArgs extends TeaserListQueryArgs>(
  response: ContentListResponse,
  meta: unknown,
  args: TArgs,
) {
  return {
    ...response,
    limit: args.limit,
    offset: args.offset || 0,
  };
}

export const traisApi = createApi({
  reducerPath: 'trais',
  baseQuery: createBaseQuery('trais', headers => {
    headers.set('site', 'trmedia');
    return headers;
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (
      isHydrateAction<{
        [reducerPath]: CombinedState<{}, never, typeof reducerPath>;
      }>(action)
    ) {
      return action.payload[reducerPath];
    }
  },
  endpoints: builder => ({
    getContent: builder.query<Article, string>({
      query: slug => `content/${slug}`,
    }),
    getContentList: builder.query<TeaserList, TeaserListQueryArgs>({
      query: filters =>
        `content.json?${argsToParams({ ...filters, type: 'news_article' })}`,
      transformResponse: transformListResponse,
    }),
  }),
});

/*

export function useGetPersistentFileValueQueryWithReturnType<
  T extends TeaserListQueryArgs,
>(queryData: T): PersistentFileValue<T['itemKey']> | undefined {
  const { data } = useGetPersistentFileValueQuery(queryData);
  return data;
}
 */

export const {
  // Auto-generated based on the defined endpoints
  useGetContentQuery,
  useGetContentListQuery,
  // Endpoints for use in SSR
  endpoints: { getContent, getContentList },
  util: { getRunningQueriesThunk },
} = traisApi;
