import type { Action, PayloadAction } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { HYDRATE } from 'next-redux-wrapper';

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isHydrateAction<TPayload>(
  action: Action,
): action is PayloadAction<TPayload> {
  return action.type === HYDRATE;
}
