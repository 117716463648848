import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { AppState } from '~/main/store';
import { isHydrateAction } from '~/types/assert';

interface ExternalScriptsState {
  loaded: string[];
}

const initialState: ExternalScriptsState = {
  loaded: [],
};

const slice = createSlice({
  name: 'externalScripts',
  initialState,
  reducers: {
    externalScriptOnLoad: (state, action) => {
      const { src } = action.payload;
      if (!state.loaded.includes(src)) {
        state.loaded.push(src);
      }
    },
  },
  extraReducers: builder => {
    builder.addDefaultCase((_state, action) => {
      if (isHydrateAction<{ externalScripts: ExternalScriptsState }>(action)) {
        return action.payload.externalScripts;
      }
    });
  },
});

export const stateSelector = (state: AppState) => state.externalScripts;

export const selectExternalLoadedScripts = createSelector(
  stateSelector,
  state => state.loaded,
);

export const { externalScriptOnLoad } = slice.actions;

export default slice;
