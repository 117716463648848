import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { canUseDOM } from 'exenv';

import { selectOrigin } from '~/main/config';
import type { AppState } from '~/main/store';

const rawBaseQuery = (
  baseUrl: string,
  prepareHeaders?: (headers: Headers) => Headers,
) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  });

/**
 * Prepends api urls with the server origin base url
 *
 * @param headersFn Optional request headers
 * @returns base query function
 */
export const createBaseQuery = (
  service: 'trais' | 'vercel' | 'auth',
  prepareHeaders?: (headers: Headers) => Headers,
) => {
  const baseQueryFn: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    let basePath =
      service === 'trais'
        ? 'api/v1/public/'
        : service === 'auth'
          ? 'truth'
          : 'api';

    // Don't proxy trais api server side
    if (!canUseDOM && service === 'trais') {
      return rawBaseQuery(
        `${process.env.TRAIS_API_URL}/${basePath}`,
        prepareHeaders,
      )(args, api, extraOptions);
    }

    // Dont proxy auth api server side
    if (!canUseDOM && service === 'auth') {
      return rawBaseQuery(
        `${process.env.AUTH_API_URL}/${basePath}`,
        prepareHeaders,
      )(args, api, extraOptions);
    }

    const origin = selectOrigin(api.getState() as AppState);
    if (origin == null) {
      return {
        error: {
          status: 400,
          statusText: 'Bad Request',
          data: 'No origin found',
        },
      };
    }

    // Change to proxy path
    if (service === 'trais') {
      basePath = basePath.replace('api/', 'trais-api');
    }

    return rawBaseQuery(`${origin}/${basePath}`, prepareHeaders)(
      args,
      api,
      extraOptions,
    );
  };

  return baseQueryFn;
};

export function argsToParams(args: Record<string, string | number>) {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(args)) {
    params.append(key, `${value}`);
  }
  return params.toString();
}
