import type { DefaultTheme } from 'styled-components';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle<{
  theme: DefaultTheme;
}>`
  /* https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
  html {
    height: -webkit-fill-available;
  }

  body, #__next {
    height: 100vh;
    /* mobile viewport bug fix */
    height: -webkit-fill-available;
  }

  ${({ theme }) => `
    body {
      background-color: ${theme.colors.background.primary}
    }
  `}
`;

export default GlobalStyles;
