import theme from '@trmediaab/theme-trmedia';
import { createGlobalStyle } from 'styled-components';

const ConsentBannerStyles = createGlobalStyle`
  /**
   * Change font on all onetrust elements
   */
  #onetrust-consent-sdk {
    font-family: ${() => theme.fonts.primary};

    *,
    *::before,
    *::after {
      -webkit-font-smoothing: inherit !important;
      font-feature-settings: 'liga', 'kern';
      text-rendering: optimizeSpeed;
    }
  }

  /**
   * Remove ugly outline
   */
  #onetrust-banner-sdk:focus {
    outline: none !important;
  }

  /**
   * Hide ugly floating cookie settings button shown after user
   * has accepted/rejected cookies. User can access settings
   * from a button in the footer instead.
   */
  #ot-sdk-btn-floating {
    display: none;
  }

  /**
   * Override to fix the worst of onetrust's absolutely broken
   * responsive layout
   */
  @media (min-width: 550px) {
    #onetrust-banner-sdk .ot-sdk-ten.ot-sdk-columns {
      width: 75% !important;
    }
  }

  @media (min-width: 1024px) {
    #onetrust-banner-sdk .ot-sdk-ten.ot-sdk-columns {
      width: 82% !important;
    }
  }

  /**
   * Overrides to change appearance of all buttons
   */
  #accept-recommended-btn-handler,
  #onetrust-accept-btn-handler,
  #onetrust-reject-all-handler,
  #filter-btn-handler,
  .ot-pc-refuse-all-handler,
  .save-preference-btn-handler {
    transition-property: background-color;
    transition-duration: ${() => `${theme.transitionDuration[0]}`};
    transition-timing-function: ${() =>
      `${theme.transitionTimingFunction.easeIn}`};
    border-radius: ${() => theme.radii[1]}px !important;
    font-size: 14px !important;
    font-weight: 600 !important;

    &:hover {
      opacity: 1 !important;
    }
  }

  #accept-recommended-btn-handler,
  #onetrust-accept-btn-handler,
  #filter-btn-handler,
  .ot-pc-refuse-all-handler,
  .save-preference-btn-handler {
    border-color: ${() => theme.colors.button.bg} !important;
    background-color: ${() => theme.colors.button.bg} !important;
    color: ${() => theme.colors.white} !important;

    &:hover {
      border-color: ${() => theme.colors.button.bgHover} !important;
      background-color: ${() => theme.colors.button.bgHover} !important;
      color: ${() => theme.colors.white} !important;
    }
  }

  /**
   * Overrides to differentiate reject button a bit
   */
  .ot-pc-refuse-all-handler,
  #onetrust-reject-all-handler {
    border-color: ${() => theme.colors.greys[0]} !important;
    background-color: ${() => theme.colors.greys[0]} !important;
    color: ${() => theme.colors.link.primary} !important;

    &:hover {
      border-color: ${() => theme.colors.greys[0]} !important;
      background-color: ${() => theme.colors.greys[0]} !important;
      color: ${() => theme.colors.link.hover} !important;
    }
  }

  /**
   * Filter button shown on popup with list of third party providers.
   * Override background color on this button which for some reason is
   * not affected by the button color setting in onetrust's admin.
   */
  #filter-btn-handler {
    background-color: ${() => theme.colors.button.bg} !important;
  }

  /**
   * Override to change appearance of toggles in cookie settings popup
   */
  #onetrust-pc-sdk .ot-tgl .ot-switch-nob {
    border-color: transparent !important;
    background-color: ${() => theme.colors.greys[1]} !important;
  }

  #onetrust-pc-sdk .ot-tgl .ot-switch-nob:before {
    border-color: ${() => theme.colors.greys[1]} !important;
    background-color: ${() => theme.colors.white} !important;
  }

  #onetrust-pc-sdk .ot-tgl input:checked +.ot-switch .ot-switch-nob {
    border-color: transparent !important;
    background-color: ${() => theme.colors.link.primary} !important;
  }

  #onetrust-pc-sdk .ot-tgl input:checked +.ot-switch .ot-switch-nob:before {
    border-color: ${() => theme.colors.white} !important;
    background-color: ${() => theme.colors.white} !important;
  }

  /**
   * Override to change appearance of "Always active" in cookie settings popup
   */
  #onetrust-pc-sdk .ot-vs-list .ot-always-active, #onetrust-pc-sdk .ot-cat-grp .ot-always-active {
    color: ${() => theme.colors.link.primary} !important;
  }

  #onetrust-pc-sdk .ot-vs-config .ot-always-active-group .ot-cat-header {
    font-weight: 600 !important;
  }

  /**
   * Override to change appearance of checkboxes in popup with list of
   * third party providers
   */
  #onetrust-pc-sdk input ~ label::before {
    border-color: ${() => theme.colors.border.primary} !important;
    background-color: ${() => theme.colors.white} !important;
  }

  #onetrust-pc-sdk input:focus ~ label::before {
    outline: none !important;
    box-shadow: rgb(136 136 136 / 50%) 0px 0px 5px 0px !important;
  }

  #onetrust-pc-sdk input:checked ~ label::before {
    border-color: ${() => theme.colors.button.bg} !important;
    background-color: ${() => theme.colors.button.bg} !important;
  }

  #onetrust-policy > div.ot-dpd-container > h3 {
    font-weight: 600 !important;
  }
`;

export default ConsentBannerStyles;
