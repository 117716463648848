import { createSelector, createSlice } from '@reduxjs/toolkit';
import { canUseDOM } from 'exenv';

import type { AppState } from '~/main/store';
import { isHydrateAction } from '~/types/assert';

interface ConfigState {
  origin?: string;
}

const initialState: ConfigState = {
  origin: '',
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addDefaultCase((_state, action) => {
      if (isHydrateAction<{ config: ConfigState }>(action)) {
        return action.payload.config;
      }
    });
  },
});

export const stateSelector = (state: AppState) => state.config;

export const selectOrigin = createSelector(stateSelector, state => {
  let origin = state.origin;
  if (origin === '' && canUseDOM) {
    origin = window.location.origin;
  }
  return origin;
});

export default slice;
