import theme from '@trmediaab/theme-trmedia';
import type { FontFaceDefinitionLocal } from '@trmediaab/zebra';
import { ThemeProvider } from '@trmediaab/zebra';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { Provider } from 'react-redux';

import ErrorBoundary from '~/components/ErrorBoundary';
import ConsentBannerStyles from '~/main/consentBannerStyles';
import GlobalStyles from '~/main/GlobalStyles';
import seoDefaults from '~/main/next-seo.config';
import { wrapper } from '~/main/store';

/**
 * Note. We preload regular, semibold and bold only to avoid too much
 * load time spent on fonts.
 */
const PreloadFonts = () => {
  const { fontFaceDefinitions, fontWeights } = theme;

  if (fontFaceDefinitions == null) {
    return null;
  }

  return (
    <Head>
      {fontFaceDefinitions
        ?.filter((fd): fd is FontFaceDefinitionLocal => fd.source === 'local')
        .flatMap(fd => fd.descriptors)
        .filter(
          ({ weight, style }) =>
            style === 'normal' &&
            weight != null &&
            [
              fontWeights.regular,
              fontWeights.semiBold,
              fontWeights.bold,
            ].includes(Number.parseInt(weight)),
        )
        .map(({ filename }) => (
          <link
            key={filename}
            rel="preload"
            href={`/fonts/${filename}.woff2`}
            as="font"
            crossOrigin=""
            type="font/woff2"
          />
        ))}
    </Head>
  );
};

export default function TrMediaApp({ Component, ...rest }: AppProps) {
  const { store, props } = wrapper.useWrappedStore(rest);
  const getLayout =
    (Component as NextPageWithLayout).getLayout || (page => page);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider
          theme={theme}
          fontBaseUrl="/fonts"
          disableVendorPrefixes={
            process.env.NEXT_PUBLIC_DISABLE_VENDOR_PREFIXES === 'true'
          }
        >
          <DefaultSeo {...seoDefaults} />
          <PreloadFonts />
          <ConsentBannerStyles />
          <GlobalStyles />
          {getLayout(<Component {...props.pageProps}></Component>)}
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}
