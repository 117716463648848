const title = 'TR Media';
const description =
  'TR Media får fler att älska trav med varumärken som t ex Travronden, Spelvärde, Jokersystemet, Köpandel, Överodds';

const defaultConfig = {
  titleTemplate: '%s · TR Media',
  defaultTitle: title,
  description,
  dangerouslySetAllPagesToNoIndex: process.env.NEXT_PUBLIC_NO_ROBOTS === 'true',
  dangerouslySetAllPagesToNoFollow:
    process.env.NEXT_PUBLIC_NO_ROBOTS === 'true',
  openGraph: {
    type: 'website',
    locale: 'sv_SE',
    url: 'https://www.trmedia.se',
    site_name: 'TR Media',
    title,
    description,
    images: [
      {
        url: 'https://media.trmedia.se/w_800,h_230,c_lpad,f_auto,fl_lossy,q_auto,b_rgb:292B2E,bo_200px_solid_rgb:292B2E,z_1.5/brands/logos/si3okwhx7yghsm0fknwo',
      },
    ],
  },
  /* TODO facebook: {
    appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  }, */
  /* TODO twitter: {
    cardType: 'summary_large_image',
    handle: '@TODO',
  }, */
  additionalLinkTags: [
    {
      rel: 'apple-touch-icon',
      href: '/tr-icon/apple-touch-icon-57x57.png',
      sizes: '57x57',
    },
    {
      rel: 'apple-touch-icon',
      href: '/tr-icon/apple-touch-icon-60x60.png',
      sizes: '60x60',
    },
    {
      rel: 'apple-touch-icon',
      href: '/tr-icon/apple-touch-icon-72x72.png',
      sizes: '72x72',
    },
    {
      rel: 'apple-touch-icon',
      href: '/tr-icon/apple-touch-icon-76x76.png',
      sizes: '76x76',
    },
    {
      rel: 'apple-touch-icon',
      href: '/tr-icon/apple-touch-icon-114x114.png',
      sizes: '114x114',
    },
    {
      rel: 'apple-touch-icon',
      href: '/tr-icon/apple-touch-icon-120x120.png',
      sizes: '120x120',
    },
    {
      rel: 'apple-touch-icon',
      href: '/tr-icon/apple-touch-icon-144x144.png',
      sizes: '144x144',
    },
    {
      rel: 'apple-touch-icon',
      href: '/tr-icon/apple-touch-icon-152x152.png',
      sizes: '152x152',
    },
    {
      rel: 'icon',
      href: '/tr-icon/favicon-16x16.png',
      type: 'image/png',
      sizes: '16x16',
    },
    {
      rel: 'icon',
      href: '/tr-icon/favicon-32x32.png',
      type: 'image/png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: '/tr-icon/favicon-96x96.png',
      type: 'image/png',
      sizes: '96x96',
    },
    {
      rel: 'icon',
      href: '/tr-icon/favicon-128x128.png',
      type: 'image/png',
      sizes: '128x128',
    },
    {
      rel: 'icon',
      href: '/tr-icon/favicon-196x196.png',
      type: 'image/png',
      sizes: '196x196',
    },
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg',
      color: '#292b2e',
    },
  ],
  additionalMetaTags: [
    /* TODO? { property: 'fb:pages', content: process.env.NEXT_PUBLIC_FB_PAGE_ID }, */
    /* {
    TODO?  name: 'facebook-domain-verification', 
      content: process.env.NEXT_PUBLIC_FB_VERIFICATION_CODE, 
    }, */
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
    { name: 'apple-mobile-web-app-title', content: 'TR Media' },
    { name: 'application-name', content: 'TR Media' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'msapplication-TileColor', content: '#ffffff' },
    { name: 'msapplication-TileImage', content: '/tr-icon/mstile-144x144.png' },
    {
      name: 'msapplication-square70x70logo',
      content: '/tr-icon/mstile-70x70.png',
    },
    {
      name: 'msapplication-square150x150logo',
      content: '/tr-icon/mstile-150x150.png',
    },
    {
      name: 'msapplication-wide310x150logo',
      content: '/tr-icon/mstile-310x150.png',
    },
    {
      name: 'msapplication-square310x150logo',
      content: '/tr-icon/mstile-310x310.png',
    },
  ],
};

export default defaultConfig;
