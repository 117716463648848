import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as React from 'react';

// https://github.com/bugsnag/bugsnag-js/pull/1363/files#diff-91f1773c520721c1eeaa8782923134006cbad2f9c490c30e1abe3c56c4f2b6a4R7
if (process.env.NEXT_PHASE !== 'phase-production-build') {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
    appVersion: process.env.APP_VERSION,
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: ['production'],
    maxBreadcrumbs: 30,
    plugins: [new BugsnagPluginReact(React)],
    onError: event => {
      const { originalError } = event;

      if (originalError.isTrusted === true) {
        event.addMetadata('Event error info', {
          eventTag: originalError.target?.tagType,
          eventSrc: originalError.target?.src,
        });
      }
    },
  });
}

export { default } from '@bugsnag/js';
