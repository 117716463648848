import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
//import { setupListeners } from '@reduxjs/toolkit/query';
import type { Context } from 'next-redux-wrapper';
import { createWrapper } from 'next-redux-wrapper';

import { traisApi } from '~/services/trais';
import { vercelApi } from '~/services/vercel';

import baseUrlState from './config';
import externalScriptsState from './externalScripts';

// Exported for Storybook
export const makeStore = (ctx: Context) => {
  let initialState;

  if ('req' in ctx && ctx.req != null) {
    initialState = {
      config: {
        origin: `${ctx.req.headers['x-forwarded-proto'] || 'http'}://${
          ctx.req.headers['x-forwarded-host'] || ctx.req.headers['host']
        }`,
      },
    };
  }

  const store = configureStore({
    reducer: {
      [traisApi.reducerPath]: traisApi.reducer,
      [vercelApi.reducerPath]: vercelApi.reducer,
      [baseUrlState.name]: baseUrlState.reducer,
      [externalScriptsState.name]: externalScriptsState.reducer,
    },
    middleware: getDefaultMiddleware =>
      // We get a type error if we use spread
      // see https://github.com/reduxjs/redux-toolkit/issues/2089
      // eslint-disable-next-line unicorn/prefer-spread
      getDefaultMiddleware().concat(traisApi.middleware, vercelApi.middleware),
    preloadedState: initialState,
    devTools: true, // !isServer,
  });

  return store;
};

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
//setupListeners(store.dispatch);

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
export type AppDispatch = AppStore['dispatch'];

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
