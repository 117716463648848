import { Box } from '@trmediaab/zebra';
import React from 'react';

import Bugsnag from '~/utils/bugsnag';

interface ErrorViewProps {
  error: Error;
  info: React.ErrorInfo;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
  showError?: boolean;
}

const BugsnagErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React);

// TODO
function ErrorView({ error, info }: ErrorViewProps) {
  return (
    <Box>
      <Box>{JSON.stringify({ error })}</Box>
      <Box>{JSON.stringify({ info })}</Box>
    </Box>
  );
}

const ErrorBoundary = ({ children, showError = true }: ErrorBoundaryProps) =>
  BugsnagErrorBoundary ? (
    <BugsnagErrorBoundary FallbackComponent={showError ? ErrorView : undefined}>
      {children}
    </BugsnagErrorBoundary>
  ) : (
    <>{children}</>
  );

ErrorBoundary.displayName = 'AppErrorBoundary';

export default ErrorBoundary;
