import { createApi } from '@reduxjs/toolkit/query/react';

import { createBaseQuery } from './utils';

export const vercelApi = createApi({
  reducerPath: 'vercel',
  baseQuery: createBaseQuery('vercel'),
  endpoints: builder => ({
    getClientInfo: builder.query<string, void>({
      query: () => 'client-info',
    }),
  }),
});

export const { useGetClientInfoQuery } = vercelApi;
